import { Routes, Route, useLocation }            from "react-router-dom";
import { useEffect }                from "react";

import Store                        from "./components/store.jsx";
import Navbar                       from "./components/navbar/navbar.jsx";
import Footer                       from "./components/footer/footer.jsx";
import Help                         from "./components/help/helpRoutes.jsx";
import HoverFooter                  from "./components/hoverFooter/hoverFooter.jsx";
import Login                        from "./components/members/login.jsx";
import Register                     from "./components/members/register.jsx";
import VerifyEmailPage              from "./components/members/verifyEmail.jsx";
import MemberDashboard              from "./components/members/memberDashboard.jsx";

import { CurrencyProvider }         from "./context/currencyContext.js";
import { ShoppingCartItemProvider } from "./context/shoppingCartContext.js";
import Support from "./components/support/Support.jsx";

export default function App() 
{
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location])

  return (
    <CurrencyProvider>
      <ShoppingCartItemProvider>
        <div id="alerts-contatiner"></div>
        <Routes>
          <Route path="/*" element={
            <div className="store-div">
              <Navbar />
              <HoverFooter />
              <Store />
              <Footer />
            </div>
          } />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/help/*" element={<Help />} />
          <Route path="/users/*" element={<MemberDashboard />} />
          <Route path="/verify" element={<VerifyEmailPage />} />
          <Route path="/support/*" element={<Support/>} />
        </Routes>
      </ShoppingCartItemProvider>
    </CurrencyProvider>
  );
}