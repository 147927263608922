import { ShopInfo } from "../../../constants/ShopInfo";

export default function IsAltsGreatLegitAndSafeToBuyFrom()
{
    return (
        <>
            <div className="support-questions-header">Is {ShopInfo.CompanyName} Legit And Safe To Buy From</div>
            <article className="support-questions-solution">
                <section>
                    <h3 className="support-answers-section-header">Who are we?</h3>
                    <p>To answer that question, we must first introduce who we are. {ShopInfo.CompanyName} was founded in 2022 with one sole mission: to enhance gamers' experiences all around the world. With that mission, we have put our blood, sweat, and tears into ensuring customers receive the product and are satisfied. We have thousands of reviews from previous customers in our Discord server and also have hundreds of reviews on our trustpilot.</p>
                </section>
                <section>
                    <h3 className="support-answers-section-header">Are we legit and safe to buy from?</h3>
                    <p>All of our products are verified and checked before they are uploaded. So are we legit? Absolutely! We take pride in being a trusted and reliable shop. Our business is fully registered and compliant with all relevant laws and regulations. We have countless satisfied customers who can vouch for our authenticity. Additionally, we use secure payment methods to protect your transactions, and our customer service team is always here to help with any concerns. You can shop with confidence knowing you're dealing with a legitimate and reputable business.</p>
                </section>
                <section>
                    <h3 className="support-answers-section-header">Why should you choose us?</h3>
                    <p>Unlike other shops that require you to go through a long process of claiming your account and switching it to your own email. We provide a solution that will allow you do to so in under a minute. We have the highest rated customer service in the industry and trained staff members who will treat ensure you feel thanked for choosing us.</p>
                    {/* <p>Not convinced? We provide cashback for every single purchase if you are registered. We deliver products instantly for the most popular payment methods and our website is programmed by the most experienced developers that allow you to have a quick and easy experience while browsing through our website.</p> */}
                </section>
                <section>
                    <h3 className="support-answers-section-header">Still Need Help?</h3>
                    <p>Contact our support through email or discord.</p>
                </section>
            </article>
        </>
    )
}