import "./navbar.css"

import logo from '../../images/logo.webp'
import shoppingCartIcon from '../../images/shoppingcart.webp'
import profile from '../../images/profile.webp'
import logoutimg from '../../images/logout.webp'
import downarrow from '../../images/downarrow.webp'
import uparrow from '../../images/uparrow.webp'

import { Link } from "react-router-dom";

import { getCookie } from "../../util/cookies";
import { logout } from "../../util/logout";
import { LINKS } from "../../constants/Links"
import { useCurrency } from "../../context/currencyContext"
import { useShoppingCart } from "../../context/shoppingCartContext"
import NavbarDropDown from "./navbarDropDown"

function Navbar()
{    
    const { shoppingCartItemCount } = useShoppingCart();

    const { currency, changeCurrency } = useCurrency();

    let down;

    const showCurrencyMenu = () =>
    {
        if(down)
        {
            document.getElementById("n-b-top-up").style.display = "inline-block";
            document.getElementById("n-b-top-down").style.display = "none";
            document.getElementById('currency-selector').style.display = "none";
        }else{
            document.getElementById("n-b-top-down").style.display = "inline-block";
            document.getElementById("n-b-top-up").style.display = "none";
            document.getElementById('currency-selector').style.display = "block";
        }
        down = !down
    }

    return (
        <div id="navbar-cont" className="n-b-cont">
            <div id="n-b-top" className="n-b-top">
                <div className="n-b-top-contents">
                    <Link className="n-b-top-item" to={"/support/popular-questions"}>Support </Link>
                    <a className="n-b-top-item" href={LINKS.TRUSTPILOT} target="_blank" rel="noreferrer">Reviews </a>
                    <div className="n-b-top-item"><span onClick={showCurrencyMenu}>{currency.currency}</span> <img id="n-b-top-up" className="n-b-top-item-img" src={downarrow} alt="down arrow"/>
                        <img id="n-b-top-down" className="n-b-top-item-img" src={uparrow} alt="up arrow"/>
                    </div>
                    <div id="currency-selector" className="currency-selector">
                        <div className="select-item" onClick={() => changeCurrency("USD")}>US Dollar (USD)</div>
                        <div className="select-item" onClick={() => changeCurrency("CAD")}>Canadian (CAD)</div>
                        <div className="select-item" onClick={() => changeCurrency("EUR")}>Euro (EUR)</div>
                        <div className="select-item" onClick={() => changeCurrency("NZD")}>New Zealand (NZD)</div>
                        <div className="select-item" onClick={() => changeCurrency("JPY")}>Japanese Yen (JPY)</div>
                        <div className="select-item" onClick={() => changeCurrency("GBP")}>Pound Sterling (GBP)</div>
                    </div>
                </div>
            </div>
            <div className="n-b-flex">
                <nav className="mc-nav-bar">
                    <Link to={"/"}>
                        <div className="mc-nav-bar-name">
                            <img className="store-logo" src={logo} alt="greatalts logo"/>
                            <div className="mc-nav-bar-n-c">
                                <div>GreatAlts</div>
                            </div>
                        </div>
                    </Link>
                    <div className="mc-nav-bar-main">
                        <Link to={"/shoppingcart"} className="shopping-cart-icon-cont">
                            <img className="shopping-cart-icon" src={shoppingCartIcon} alt="shopping cart icon"/>
                            <strong className="shopping-cart-number">{shoppingCartItemCount}</strong>
                        </Link>
                        {/** If the user if logged in then show the dashboard and signout button */}
                        { getCookie("UTOK") ? 
                        <div className="n-b-logged-in">
                            <Link to={"/users"} id="nav-bar-dashboard" className="shopping-cart-icon-cont">
                                <img className="shopping-cart-icon" src={profile} alt="profile icon"/>
                                <div className="profile-text">Dashboard</div>
                            </Link>
                            <div className="shopping-cart-icon-cont" onClick={logout}>
                                <img className="shopping-cart-icon" src={logoutimg} alt="exit icon"/>
                                <div className="profile-text">Logout</div>
                            </div>
                        </div> :
                        <Link to={"/login"}>
                            <div className="shopping-cart-icon-cont">
                                <img className="shopping-cart-icon" src={profile} alt="profile icon"/>
                                <div className="profile-text">Login</div>
                            </div>
                        </Link>}
                    </div>
                    <NavbarDropDown />
                </nav>
                <nav id="pn-nav-bar" className="pn-nav-bar">
                    <ol>
                        <li className="pn-nav-bar-item"><a href="#mc-fa">Best Sellers</a></li>
                        <li className="pn-nav-bar-item"><a href="#hypixel-fas">Hypixel</a></li>
                        <li className="pn-nav-bar-item"><a href="#hypixel-temp">Temporary Alts</a></li>
                        <li className="pn-nav-bar-item"><a href="#skyblock">Skyblock</a></li>
                        <li className="pn-nav-bar-item"><a href="#capes">Capes</a></li>
                        <li className="pn-nav-bar-item"><a href="#vpns">VPNs</a></li>
                        {/* <li className="pn-nav-bar-item" id="discord-nav"><a id="discord-nav" href="#discord">Nitro</a></li> */}
                    </ol>
                </nav>
            </div>
        </div>
    )
}

export default Navbar;