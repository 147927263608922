import { useEffect, useState }              from "react";

import { hideElementById, showElementById } from "../../util/hideShowElementById";
import { Link, useNavigate }                from "react-router-dom";
import { registerMember }                   from "../../services/user";
import { isValidEmail }                     from "../../util/isValidEmail";
import { isValidPassword }                  from "../../util/isValidPassword";
import { getCookie, setCookie }                        from "../../util/cookies";

import Navbar                               from "../navbar/navbar";
import RegistrationSuccess                  from "./registrationSuccess";

import "./css/login.css";

function Register()
{
    const [ email, setEmail ] = useState("");
    const [ password, setPassword ] = useState("");
    const [ confirmPassword, setConfirmPassword ] = useState("");
    const [ accepted, setAccepted ] = useState(false);

    let navigate = useNavigate(); // Setup to navigate to different routes

    useEffect(() => {
        hideElementById('pn-nav-bar');
        hideElementById('n-b-top');

        if(getCookie("UTOK"))
        {
            navigate("/users")
        }
    }, [])

    const onSubmit = async (e) =>
    {
        e.preventDefault()

        hideErrors()

        if(!accepted)
            return showElementById("s-r-tos-error")
        else if(!isValidEmail(email))
            return showElementById("s-r-invalid-email-error")
        else if(!isValidPassword(password))
            return showElementById("s-r-valid-password-error")
        else if(password !== confirmPassword)
            return showElementById("s-r-password-mismatch-error")

        const registerRequest = await registerMember(
            {
                email: email,
                password: password
            })

        switch(registerRequest.data.message)
        {
            case 'SUCCESSFULLY_REGISTERED':
                let token = registerRequest.headers.authorization;

                setCookie("RTOK", token, 30)
                hideElementById("member-register-cont");
                return showElementById('verify-cont', 'flex');
            case 'EMAIL_ALREADY_USED':
                return showElementById("s-r-existing-email-error")
            case 'INTERNAL_SERVER_ERROR':
                return showElementById("s-r-unknown-error")
        }
    }

    /**
     * Function that will get all elements with the class name "s-r-error" 
     * and hide them from the dom
     */
    const hideErrors = () =>
    {
        const errorElements = document.getElementsByClassName("s-r-error")

        for(let i = 0; i < errorElements.length; i++)
        {
            const element = errorElements[i];
            element.style.display = "none"
        }
    }

    return (
    <div>
        <Navbar />
        <form id="member-register-cont" className="member-login-cont" onSubmit={onSubmit}>
            <div className="s-r-header-cont">
                <h3 className="s-r-header">Create an account</h3>
                {/* <p className="s-r-h-msg">Join the millions of users and earn cashback on every purchase!</p> */}
                <p className="s-r-h-msg">Join the millions of users and track every order!</p>
            </div>
            <div className="s-r-input-cont">
                <h4 className="s-r-input-header">Enter your email</h4>
                <input className="s-r-input" type="email" onChange={(e) => setEmail(e.target.value)}/>
                <span id="s-r-invalid-email-error" className="s-r-error" aria-required>Enter a valid email.</span>
                <span id="s-r-existing-email-error" className="s-r-error">Another user is using this email.</span>
            </div>
            <div className="s-r-input-cont">
                <h4 className="s-r-input-header">Create a password</h4>
                <input className="s-r-input" type="password" onChange={(e) => setPassword(e.target.value)}/>
                <div id="s-r-valid-password-error" className="s-r-error">
                    Password requirements
                    <ul className="s-r-error-list">
                        <li>1 Uppercase Letter</li>
                        <li>1 Lowercase letter</li>
                        <li>1 Digit</li>
                        <li>8 characters long</li>
                    </ul>
                </div>
            </div>
            <div className="s-r-input-cont">
                <h4 className="s-r-input-header">Confirm your password</h4>
                <input className="s-r-input" type="password" onChange={(e) => setConfirmPassword(e.target.value)}/>
                <span id="s-r-password-mismatch-error" className="s-r-error">Password does not match</span>
            </div>
            <div className="s-r-agree-cont">
                <div className="s-r-agree-main">
                    <input className="s-r-checkbox" type="checkbox" onChange={() => setAccepted(!accepted)}/>
                    <span className="s-r-checkbox-msg">By checking, you agree to all <Link className="register-tos-link" to={"/help/terms-of-service"}>terms and services</Link></span>
                </div>
                <span id="s-r-tos-error" className="s-r-error">Agree to our terms of services to register.</span>
                <span id="s-r-unknown-error" className="s-r-error">Unknown error on our end. Please try again later.</span>
            </div>
            <input className="s-r-submit-btn" value="Create account" type="submit"/>
            <div className="s-r-footer">
                Already Registered? <Link to={"/login"} className="s-r-switch">Sign in</Link> to track your orders!
                {/* Already Registered? <Link to={"/login"} className="s-r-switch">Sign in</Link> to earn cashback and view your orders! */}
            </div>
        </form>
        <RegistrationSuccess email={email}/>
    </div>)
}

export default Register;