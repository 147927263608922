import { useState } from "react";
import { ShopProducts } from "../../constants/AccountsInfo";

import { deleteShoppingCartItemById, updateShoppingCartItem } from "../../services/shoppingCart";
import { useCurrency } from "../../context/currencyContext";
import { useShoppingCart } from "../../context/shoppingCartContext";
import { addAlert } from "../../util/showNotif";

function ShoppingCartItem({shoppingCartItem, changed, setChanged})
{
    const { setShoppingCartItemCount } = useShoppingCart();
    const { currency } = useCurrency();

    const [ quantity, setQuantity ] = useState(shoppingCartItem.quantity)

    const handleRemove = async () =>
    {
        const response = await deleteShoppingCartItemById(shoppingCartItem.shoppingCartItemId);

        if(response.status === 200)
        {
            setChanged(!changed);
            setShoppingCartItemCount(response.data.itemsCount);
        }
    }

    /**
     * Change the quantity for a product in a shoppingCartItem
     * 
     * @param {*} type Increase or decrease the quantity "up" and "down"
     */
    const changeQuantity = async (type) =>
    {
        // Since states don't update instantly, assign a tempQuantity
        let tempQuantity = quantity;

        // Increment or Decrement
        if(type === "down")
        {
            if(quantity > 1)
                tempQuantity -= 1
        }else{
            if(quantity < shoppingCartItem.product.quantity)
                tempQuantity += 1
        }

        // If quantity was changed then update the shopping cart
        if(tempQuantity !== quantity)
        {
            const response = await updateShoppingCartItem(shoppingCartItem.shoppingCartItemId,
                {
                    quantity: tempQuantity
                }
            );

            if(response.status === 400)
                return addAlert("Can't purchase under the minimum", "warning", 4000);
            else if(response.status === 200 && response.data.message === "INSUFFICIENT_STOCK")
                return addAlert("Not enough stock", "warning", 4000);

            // Make useEffect() for shopping cart run again.
            setChanged(!changed)
            setQuantity(tempQuantity)
        }
    }

    return (
        <div className="s-c-i-p-item">
            <img className="s-c-i-p-info-img" src={ShopProducts.get(shoppingCartItem.product.productNameId).imageUrl} alt="Product Thumbnail"/>
            <div className="s-c-i-p-info">
                <h4 className="s-c-i-p-header">{shoppingCartItem.product.title}</h4>
                <div className="s-c-i-p-actions">
                    <div className="s-c-i-p-price">{currency.symbol}{((shoppingCartItem.product.price * currency.rate) * quantity).toFixed(2)}</div>
                    <div className="s-c-i-p-quantity-cont">
                        <div className="s-c-i-p-quantity-setter s-c-i-p-quantity-down" onClick={() => changeQuantity("down")}>-</div>
                        <div className="s-c-i-p-quantity">{quantity}</div>
                        <div className="s-c-i-p-quantity-setter s-c-i-p-quantity-up"  onClick={() => changeQuantity("up")}>+</div>
                    </div>
                    <div className="s-c-i-p-remove" onClick={handleRemove}>Delete <i className="fas fa-trash"/></div>
                </div>
            </div>
        </div>
    )
}

export default ShoppingCartItem;