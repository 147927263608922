export default function DoYouSellMineconCapes()
{
    return (
        <>
            <div className="support-questions-header">Do You Sell Minecon Capes</div>
            <article className="support-questions-solution">
                <p>Minecon capes are capes, directly from Mojang, that are attached to a player's profile without any mods/clients needed. These capes are super rare and were given out during the Mojang Minecon events back in 2011, 2012, 2013, 2015 and 2016.</p>
                <section>
                    <h3 className="support-answers-section-header">Do we sell?</h3>
                    <p>Many members have suggested that we sell Minecon capes. If you want to purchase a Minecon cape please read the rest of the article.</p>
                </section>
                <section>
                    <h3 className="support-answers-section-header">Pricing?</h3>
                    <p>Due to the extreme rarity of Minecons, you first need to know that the average price of a Minecon account is around $1,000 USD. Depending on how many name changes and the year of the cape along with how the Minecon account was obtained, the price of a Minecon can vary from $700 to $5,000 USD.</p>
                    <p>Most people think they can get a Minecon for $50 to $200 USD. However, this is not the case. If there is anyone advertising this price, the account was most likely stolen (which will eventually lead to the account getting taken back by the owner) or you will get scammed. We want you to understand the pricing of Minecons before you continue to make your decision.</p>
                </section>
                <section>
                    <h3 className="support-answers-section-header">I have read the pricing. How do I buy?</h3>
                    <p>If you understand that the average pricing for a Minecon is around $1,000 USD and still want to purchase, create a ticket for "General Question" in the discord server. After this you will be required to deposit a minimum of $1,000 before any process is made to your purchase. After that we will search for the specific Minecon you want.</p>
                    <p>If the Minecon found is under $1,000, you will be refunded the difference, but if the Minecon is above your deposit, then you will be asked to pay more.</p>
                    <p>Why do we do this? Our time is valuable and almost 95% of clients who ask for Minecon capes see the price and back out of the deal, not understanding that we have spent hours researching in finding the product.</p>
                </section>
                <section>
                    <h3 className="support-answers-section-header">Terms and Service/Warranty for Minecons</h3>
                    <ul>
                        <li>Before purchase, you must make a minimum deposit of $1,000 for proof you have the funds and for proof you are 100% willing to purchase a Minecon.</li>
                        <li>After the deal is done and once the account is transferred. There is no warranty.</li>
                        <li>The username of the account is only given after the purchase.</li>
                    </ul>
                </section>
                <section>
                    <h3 className="support-answers-section-header">Still Need Help?</h3>
                    <p>Contact our support through email or discord.</p>
                </section>
            </article>
        </>
    )
}