function ProductDetails({instructions, features, warrantyFeatures})
{
    return (
        <div className="p-p-e-item">
            <p className="p-p-e-item-instructions">{instructions}</p>
            <h3 className="p-p-e-item-more-header">More About This Product</h3>
            <ol className="p-p-e-more">
                {features.map(feature => {
                    return <li>{feature}</li>
                })}
            </ol>
            <div className="p-p-product-details-header">Warranty Details</div>
            <p className='p-p-e-item-instructions'>This warranty is for this product and may vary for all other products.</p>
            <h3 className="p-p-e-item-more-header">Specifications</h3>
            <ol className="p-p-e-more">
                {warrantyFeatures.map(feature => {
                    return <li>{feature}</li>
                })}
            </ol>
        </div>
    )
}

export default ProductDetails;