import api from "./apiConfig";
import { RouteConstants } from "../constants/AppConstants";


export const getInvoiceById = async (invoiceId) =>
{
    try{
        const response = await api.get(`${RouteConstants.INVOICES_URL}/${invoiceId}`)
        console.log(response)
        return response;
    }catch(error){
        console.log(error)
        return error.response;
    }
}

export const getOrderStatusById = async (orderId) => {
    try{
        const response = await api.get(`${RouteConstants.INVOICES_URL}/status/${orderId}`)
        return response;
    }catch(err){
        console.log(err)
        return err.response;
    }
}

export const getInvoices = async () => {
    try{
        const response = await api.get(`${RouteConstants.INVOICES_URL}`)
        return response.data;
    }catch(error){
        console.error(error)
    }
}

export const cashAppCheck = async (orderId, paymentId) =>
{
    try{
        const response = await api.post(`${RouteConstants.INVOICES_URL}/cashapp`, {
            orderId: orderId,
            paymentId: paymentId
        })
        return response;
    }catch(err){
        console.log(err)
        return err.response;
    }
}

export const createInvoice = async (orderInfo) => {
    try{
        const response = await api.post(`${RouteConstants.INVOICES_URL}`, orderInfo)
        return response
    }catch(err){
        console.log(err)
        return err.response;
    }
}